import React, {Component} from 'react';
import Fade from 'react-reveal';
import ButtonArray from "../../shared/ButtonArray";
import NavigationElement from "../../shared/NavigationElement";
import Instruction from "../../shared/Instruction";
import Cookies from "react-cookie/es6/Cookies";

class Tanks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            steps: [
                {
                    key: 'tankpit',
                    title: 'Tank Pit ' + props.tankPitId,
                    href: "/tree/tankpits/all"
                },
                {
                    key: 'tank',
                    active: true,
                    title: 'Tank',
                },
                {key: 'item', disabled: true, title: 'Item'},
            ],
        };
    }

    componentDidMount() {
        let self = this;
        console.log(process.env.REACT_APP_API_BASE_URL);
        const cookies = new Cookies();
        fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/tankpit/" + this.props.tankPitId + "/tanks",
            {
                headers: {
                    'Content-Type': 'application/json',
                    'X-Auth-Token': cookies.get('authToken')
                }
            }).then(function (response) {
                if (!response.ok) {
                    console.log("error", response);
                    window.location.replace("/login");
                    throw new Error(response.statusText);
                }
                return response.json()
            }).then(function (data) {
                console.log(data.tanks);
                let i;
                for (i = 0; i < data.tanks.length; i++) {
	                data.tanks[i].name = data.tanks[i]['tankNumber'];
                    data.tanks[i].url = "/tree/tankpits/" + self.props.tankPitId + "/tanks/" + data.tanks[i]['tankNumber'] + "/locations/all";

	                if (data.tanks[i].completionStatus.completed === data.tanks[i].completionStatus.total) {
		                data.tanks[i].color = "#20AA01";
	                } else {
		                data.tanks[i].color = "#4E4E50";
	                }
                }
                console.log(data.tanks);
                self.setState({items: data.tanks});
            }).catch(error => console.log(error));
    }

    render() {
        return (
            <div>
                <Fade>
                    <NavigationElement items={this.state.steps}/>
	                <Instruction text="Select a tank" previousLink="steel/tankpits/all"/>
                    <ButtonArray prefix="Tank " items={this.state.items}/>
                </Fade>
            </div>
        );
    }
}

export default Tanks;