import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import {translate} from 'react-multi-lang';

import './App.css';
import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./components/collections/Home";
import NoRef from "./components/404";
import SignIn from "./components/collections/SignIn";
import TankPits from "./components/collections/tree/TankPits";
import Dashboard from "./components/collections/Dashboard";
import Tanks from "./components/collections/tree/Tanks";
import Locations from "./components/collections/tree/Locations";
import Redirect from "react-router/es/Redirect";
import {Cookies, withCookies} from 'react-cookie';
import {instanceOf} from 'prop-types';
import Approver from "./components/status-approval/Approver";
import Inspections from "./components/collections/Inspections";
import Type from "./components/collections/tree/Type";
import Plates from "./components/collections/tree/Plates";

const NoMatch = ({location}) => (
	<div>
		<NoRef location={location}/>
	</div>
);

function PrivateRoute({component: Component, ...rest}) {
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated() ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: {from: props.location}
						}}
					/>
				)
			}
		/>
	);
}

function isAuthenticated() {
	const cookies = new Cookies();
	console.log(!!cookies.get("authToken"));
	return !!cookies.get("authToken");
}

function TankChild({match}) {
	return (
		<div>
			<Tanks tankPitId={match.params.tankPitId}/>
		</div>
	);
}

function LocationChild({match}) {
	return (
		<div>
			<Locations tankPitId={match.params.tankPitId} tankNumber={match.params.tankNumber}/>
		</div>
	);
}

function PrefabChild({match}) {
	return (
		<div>
			<Type tankPitId={match.params.tankPitId} tankNumber={match.params.tankNumber}/>
		</div>
	);
}

function PlateChild({match}) {
	return (
		<div>
			<Plates tankPitId={match.params.tankPitId} tankNumber={match.params.tankNumber}/>
		</div>
	);
}

function ApproverChild({match}) {
	return (
		<div>
			<Approver id={match.params.id}/>
		</div>
	);
}

class App extends Component {
	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);

		const {cookies} = props;
		this.state = {
			authenticationToken: cookies.get('X-Authentication-Token') || ""
		};
	}

	render() {
		return (
			<div className="App">
				<Header/>

				<Switch>
					<Route path="/" exact component={Home}/>
					<Route path="/login" component={SignIn}/>
					<PrivateRoute path="/inspections" component={Inspections}/>
					<Route path="/tracker" component={Locations}/>
					<Route path="/waive/:id" component={ApproverChild}/>
					<PrivateRoute path="/tree/tankpits/all" component={TankPits}/>
					<PrivateRoute path="/tree/tankpits/:tankPitId/tanks/all" exact component={TankChild}/>
					<PrivateRoute path="/tree/tankpits/:tankPitId/tanks/:tankNumber/locations/all"
					              component={LocationChild}/>
					<PrivateRoute path="/tree/tankpits/:tankPitId/tanks/:tankNumber/locations/prefab" exact
					              component={PrefabChild}/>
					<PrivateRoute path="/tree/tankpits/:tankPitId/tanks/:tankNumber/locations/prefab/plates"
					              component={PlateChild}/>
					<PrivateRoute path="/dashboard" component={Dashboard}/>
					<Route component={NoMatch}/>
				</Switch>

				<Fade bottom>
					<Footer/>
				</Fade>
			</div>
		);
	}
}

export default withCookies(translate(App));
