import React, {Component} from 'react';
import Message from "semantic-ui-react/dist/commonjs/collections/Message/Message";

class Approver extends Component {
    constructor(props) {
	    super(props);

	    this.state = {
		    success: false
        };

        let self=this;
	    fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/inspection/waive-token/' + this.props.id, {
		    method: 'POST',
		    headers: {'Content-Type': 'application/json'},
	    }).then(function (response) {
		    if (!response.ok) {
			    console.log("error", response);
			    return response.json();
		    }
		    self.setState({success: true})
	    }).then(data => {
		    console.log(data);
		    console.log(data.propertyErrors.inspection.errorMessage);
		    self.setState({errorMessage: data.propertyErrors.inspection.errorMessage})
	    }).catch(error => console.log(error));
    };

    render() {
        return (
            <div>
            {this.state.success === true ?
                <Message positive>
                    <p>
                        Your response has been submitted
                    </p>
                </Message> : <Message negative>
                    <p>
                        Response failed
                    </p>
                </Message>}
            </div>
        );
    }
}

export default Approver;