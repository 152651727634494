import React, {Component} from "react";
import {translate} from "react-multi-lang"

class Login extends Component {
	render() {
		return (
			<div className="services-wrapper">
				<div className="logo-component">
					<h1 className="logo-header" style={{fontWeight: "bold", marginBottom: "0"}}>HES QA/QC
						Tracker</h1>
					<h1 className="logo-header" style={{fontWeight: "bold", paddingBottom: "0.5em"}}>Login</h1>
				</div>
			</div>
		);
	}
}

export default translate(Login);
