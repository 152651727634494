import React, {Component} from 'react';
import Fade from "react-reveal/Fade";
import Login from "../authentication/Login";
import LargeLogo from "../LargeLogo";


class SignIn extends Component {
	render() {
		return (
			<div className="portfolio-wrapper">
				<Fade>
					<LargeLogo/>
					<Login/>
				</Fade>
			</div>
		);
	}
}

export default SignIn;