import React, {Component} from 'react';
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";

const buttonArrayStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingBottom: "1em",
	paddingTop: "1.5em",
	backgroundColor: "white"
};


class ButtonArray extends Component {
	render() {
		return (
			<div style={buttonArrayStyle}>
				{this.props.items.map((item, index) => {
					return <Button className="red-hover"
					               style={{
						               hoverColor: "red",
						               width: "48%",
						               margin: "1%",
						               fontSize: "22px",
						               backgroundColor: item.color
					               }}
					               key={index} href={item.url} primary>{this.props.prefix + item.name}</Button>
				})}
			</div>
		);
	}
}

export default ButtonArray;