import React, {Component} from 'react';
import Fade from 'react-reveal';
import Cookies from "react-cookie/es6/Cookies";
import InspectionArray from "../shared/InspectionArray";
import {Button} from "semantic-ui-react";

class Inspections extends Component {
	constructor(props) {
		super(props);

		this.state = {
			inspectionItems: [],
			stage: 'ongoing'
		};
	}

	componentDidMount() {
		this.refreshInspections()
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.inspectionItems !== this.state.inspectionItems) {
			console.log("update");
			// this.refreshInspections();
		}
	}

	refreshInspections() {
		let self = this;
		const cookies = new Cookies();
		fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/inspection/" + this.state.stage, {
			headers: {
				'Content-Type': 'application/json',
				'X-Auth-Token': cookies.get('authToken')
			}
		}).then(function (response) {
			if(response.status === 403){
				window.location.href = ("/login")
			}

			if (!response.ok) {
				console.log("error", response);
				// window.location.replace("/login");
				throw new Error(response.statusText);
			}
			return response.json()
		}).then(data => {
			console.log(data);
			self.setState({inspectionItems: data})
		}).catch(error => console.log(error));
	}

	changeStage = (stage) => {
		let self = this;
		this.setState({stage: stage}, function() {
			self.refreshInspections();
		});
	};


	render() {
		return (
			<div>
				<Fade>
					{/*<NavigationElement items={this.state.steps}/>*/}
					{/*<Instruction text="NOTICE: Check specifications and the correct milestone before check-in"/>*/}
					<div style={{margin: "auto", width: "500px", maxWidth: "90%", paddingTop: "1.25em"}}>
						<Button.Group align="center" size='large' fluid equal>
							<Button onClick={() => this.changeStage("unassigned")} active={this.state.stage === "open"}>Unassigned</Button>
							<Button.Or/>
							<Button onClick={() => this.changeStage("ongoing")} active={this.state.stage === "ongoing"}>Ongoing</Button>
							<Button.Or/>
							<Button onClick={() => this.changeStage("completed")} active={this.state.stage === "completed"}>Completed</Button>
					</Button.Group>
					</div>

					<InspectionArray items={this.state.inspectionItems} stage={this.state.stage}/>
				</Fade>
			</div>
		);
	}
}

export default Inspections;
