import React, {Component} from 'react';
import Fade from 'react-reveal';
import FullWidthButtonArray from "../../shared/FullWidthButtonArray";

class Type extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonArray: []
		};
	};

	componentDidMount() {
		this.setState({
			buttonArray: [
				{
					name: "Pladafdates",
					url: `/tree/tankpits/${this.props.tankPitId}/tanks/${this.props.tankNumber}/locations/prefab/plates/`
				},
				{
					name: "adsfasd",
					url: "/",
				}
			]
		})
	}

	render() {
		return (
			<div>
				<Fade>
					<FullWidthButtonArray prefix="" items={this.state.buttonArray}/>
				</Fade>
			</div>
		);
	}
}

export default Type;