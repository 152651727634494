import React, {Component} from 'react';
import Fade from "react-reveal/Fade";


class Dashboard extends Component {
	render() {
		return (
			<div style={{height: "800px"}}>
				<Fade>
					<iframe title="dash" width="100%" height="800px"
					        style={{overflow: "hidden", height: "100%", width: "100%"}}
					        src="https://app.powerbi.com/view?r=eyJrIjoiMDU0YjdhNzEtMGQyOC00NmM1LWEwNjYtNDczNWFkOTIxMGU0IiwidCI6ImViOGY4MDAxLTRkOTItNDU5ZC1hMTA1LTQ4Nzk4MzY5NmU4ZCIsImMiOjl9"
					        frameBorder="0" allowFullScreen="true"/>
				</Fade>
			</div>
		);
	}
}

export default Dashboard;