import React, {Component} from "react";
import {Link} from "react-router-dom";

import {translate} from "react-multi-lang";
import LeftLogo from "./LeftLogo";
import Cookies from "react-cookie/es6/Cookies";


class Header extends Component {
	handleSignOut = () => {
		const cookies = new Cookies();
		cookies.remove('authToken', {path: '/'});
		window.location.replace("/login")
	};

	static isAuthenticated() {
		const cookies = new Cookies();
		return !!cookies.get("authToken");
	}

	render() {
		return (
			<div>
				<LeftLogo/>
				<header className="header">
					<input className="menu-btn" type="checkbox" id="menu-btn"/>
					<label className="menu-icon" htmlFor="menu-btn"><span className="navicon"/></label>

					{Header.isAuthenticated() ? <ul className="menu">
						<li><Link to="/">How does it work?</Link></li>
						<li><Link to="/inspections">Inspections</Link></li>
						{/*<li><Link to="/tree/tankpits/all">Tree</Link></li>*/}
						{/* eslint-disable-next-line */}
						<li style={{float: "right"}}><a onClick={() => {
							this.handleSignOut()
						}}>Sign Out</a></li>
					</ul> : <ul className="menu">
						<li style={{float: "right"}}><Link to="/login">Sign In</Link></li>
					</ul>}

				</header>
			</div>
		);
	}
}

export default translate(Header);
