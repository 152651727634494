import React, {Component} from 'react';
import Step from "semantic-ui-react/dist/commonjs/elements/Step/Step";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Cookies from "react-cookie/es6/Cookies";

const centerStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingBottom: "2em",
	paddingTop: "1em",
	backgroundColor: "white"
};

class Home extends Component {
	static isAuthenticated() {
		const cookies = new Cookies();
		console.log(!!cookies.get("authToken"));
		return !!cookies.get("authToken");
	}

	render() {
		return (
			<div>
				<div style={centerStyle}>
					<h2 style={{fontSize: "24px", marginLeft: "0.5em"}}>How does it work?</h2>
					<Step.Group fluid vertical>
						{!Home.isAuthenticated() ? <Step href="login" active>
							<Icon name='hand point up outline'/>
							<Step.Content>
								<Step.Title>Sign in </Step.Title>
								<Step.Description>Use the credentials supplied by your administrator</Step.Description>
							</Step.Content>
						</Step> : null}


						<Step href="inspections">
							<Icon name='hand point up outline'/>
							<Step.Content>
								<Step.Title>Go to inspections</Step.Title>
								<Step.Description>Here, all items that have been held for inspection are
									visible</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='help'/>
							<Step.Content>
								<Step.Title>Waive or inspect</Step.Title>
								<Step.Description>If you think an inspection can be waived, click the "Waive" button at
									the bottom</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='magnify'/>
							<Step.Content>
								<Step.Title>Approve all stages</Step.Title>
								<Step.Description>If you choose to inspect, approvals have to be made in sequence. E.g.
									when signing off stage 2, <br/> everything from stage 1 needs to be approved
									beforehand.</Step.Description>
							</Step.Content>
						</Step>
						<Step>
							<Icon name='checkmark' color='green'/>
							<Step.Content>
								<Step.Title>Complete the last approval</Step.Title>
								<Step.Description>Once everything has been approved, the inspection request will
									automatically <br/> be closed, and business can continue as usual in the HHTT steel
									tracker</Step.Description>
							</Step.Content>
						</Step>

						<Step>
							<Icon name='dashboard'/>
							<Step.Content>
								<Step.Title>View dashboard</Step.Title>
								<Step.Description>Go to the dashboard to see the total progress</Step.Description>
							</Step.Content>
						</Step>

					</Step.Group>
				</div>
			</div>
		);
	}
}

export default Home;