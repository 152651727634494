import React, {Component} from "react";
import {translate} from "react-multi-lang"
import logo from "../images/logo/logo.png"
import Link from "react-router-dom/es/Link";

const containerStyle = {
    background: "white",
    position: "fixed",
    width: "100%",
    zIndex: "3",
    color: "white",
    top: "0",

};

const logoStyle = {
    maxWidth: "800px",
    margin: "auto",
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingLeft: "1em"
};

const logoImageStyle = {
    float: "left",
    width: "100px"
};

const logoText = {
    fontWeight: "bold",
    float: "left",
};


class LeftLogo extends Component {
    render() {
        return (
            <div className="hes-header-container" style={containerStyle}>
                <div style={logoStyle}>
                    <Link to="/">
                        <img className="hes-logo" src={logo} style={logoImageStyle} alt="hes logo"/>
                        <h1 className="hes-header" style={logoText}>HHTT QA/QC Tracker</h1>
                    </Link>
                </div>
            </div>
        );
    }
}

export default translate(LeftLogo);
