import React, {Component} from 'react';
import Table from "semantic-ui-react/dist/commonjs/collections/Table/Table";
import Icon from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import Button from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import Cookies from "react-cookie/es6/Cookies";
import Modal from "semantic-ui-react/dist/commonjs/modules/Modal/Modal";
import {Message} from "semantic-ui-react";

const buttonArrayStyle = {
	maxWidth: "800px",
	margin: "auto",
	paddingTop: "1.2em",
	padding: "2em, 1em",
	backgroundColor: "white"
};

const tableStyle = {
	width: "98%",
	margin: "1%",
	fontSize: "15px",
	backgroundColor: "#016A9B"
};

class InspectionArray extends Component {
	constructor(props) {
		super(props);
		console.log(props);

		this.state = {
			items: props.items,
			currentItem: null,
			message: '',
			open: false,
			method: 'PUT',
			errorMessage: ''
		}
	}

	componentWillReceiveProps(nextProps, nextContent) {
		this.setState({items: nextProps.items})
	}

	refreshInspections() {
		let self = this;
		const cookies = new Cookies();
		fetch(process.env.REACT_APP_API_BASE_URL + "/hes-tracker/inspection/" + this.props.stage, {
			headers: {
				'Content-Type': 'application/json',
				'X-Auth-Token': cookies.get('authToken')
			}
		}).then(function (response) {
			if (!response.ok) {
				console.log("error", response);
				// window.location.replace("/login");
				throw new Error(response.statusText);
			}
			return response.json()
		}).then(data => {
			console.log("fetched", data);
			self.setState({items: data})
		}).catch(error => console.log(error));
	}

	updateStatus = (item, name, method = 'PUT') => {
		const cookies = new Cookies();
		item[name] = !item[name];

		console.log(method, item);

		let self = this;

		if (method === 'PUT') {
			fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/inspection/' + item.id, {
				method: method,
				headers: {'Content-Type': 'application/json', 'X-Auth-Token': cookies.get('authToken')},
				body: JSON.stringify({dim: item.dim, qcDoc: item.qcDoc, visual: item.visual, ndt: item.ndt})
			}).then(function (response) {
				if (!response.ok) {
					console.log("error", response);
					return response.json();
				}
				self.refreshInspections();
				self.close();
			}).then(data => {
				if (data.propertyErrors !== null) {
					self.setState({errorMessage: data.propertyErrors.inspection.errorMessage})
				}
			}).catch(error => console.log(error));
		} else if (method === 'waive' || method === 'reset' || method === "start-inspection" ) {
			fetch(process.env.REACT_APP_API_BASE_URL + '/hes-tracker/inspection/' + item.id + '/' + method, {
				method: 'POST',
				headers: {'Content-Type': 'application/json', 'X-Auth-Token': cookies.get('authToken')},
			}).then(function (response) {
				if (!response.ok) {
					console.log("error", response);
					return response.json();
				}
				self.close();
			}).then(data => {
				console.log(data.propertyErrors.inspection.errorMessage);
				self.setState({errorMessage: data.propertyErrors.inspection.errorMessage})
			}).catch(error => console.log(error));
		}
	};


	state = {open: false, currentItem: '', status: '', message: ''};

	show = (item, name, message, method = 'PUT') => () => {
		this.setState({
			currentItem: item,
			name: name,
			method: method,
			open: true,
			message: message
		});
	};
	close = () => {
		if (this.state.errorMessage === "") {
			this.setState({open: false});
		} else {
			this.setState({open: false, errorMessage: ''});
		}
		this.refreshInspections();
	};

	renderButton(item, name) {
		if (this.props.stage === "completed") {

			if (name === "waived") {
				return (
					<div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
						<Button color="red" onClick={this.show(item, name,
							"Are you sure you want to reset this item? This action will remove it from this page", 'reset')}>
							<Icon name="redo"/>
							Reset
						</Button>
					</div>
				);
			} else {
				return (
					<Button disabled>
						{item[name] ? [<Icon name="check"/>, "Completed"] : [<Icon name="fast forward"/>, "Waived"]}
					</Button>
				);
			}

		} else if(this.props.stage=== "ongoing") {

			if (name === "waived") {
				return (
					<div style={{display:'flex', flexDirection:'row', justifyContent:'flex-end'}}>
						<Button onClick={this.show(item, name,
							"Are you sure you want to waive this item? This action will remove it from this page", 'waive')}>
							<Icon name="trash"/>
							Waive
						</Button>
					</div>
				);
			} else if (item[name]) {
				return (
					<Icon color='green'
					      name='checkmark'
					      size='large'
					      Button
					      onClick={this.show(item, name,
						      "Are you sure you want to cancel approval for the property " + name + "?")}/>
				);
			} else if (!item[name]) {
				return (
					<Button onClick={this.show(item, name,
						"Are you sure you want to approve item for the property " + name + "?")}>
						<Icon name="check"/>
						Approve
					</Button>
				);
			}
		}
		else if (this.props.stage === "unassigned"){
			return <div style={{display:'flex', justifyContent:'center'}}>
				<Button onClick={this.show(item, name,
					"Are you sure you want to inspect this item? This action will remove it from this page", 'start-inspection')}>
					<Icon name="search"/>
					Mark for inspection
				</Button>
				<Button onClick={this.show(item, name,
					"Are you sure you want to waive this item? This action will remove it from this page", 'waive')}>
					<Icon name="trash"/>
					Waive
				</Button>
			</div>

		}



	};

	render() {
		const {open} = this.state;

		return (
			<div style={buttonArrayStyle}>
				{this.state.items.map((item, index) => {
					let color;

					if (this.props.stage !== "completed") {
						color = "#006A9B"
					} else if (item.waived) {
						color = "grey"
					} else if (!item.waived) {
						color = "green"
					}

					return <Table style={tableStyle} key={index} color={color} celled unstackable
					              selectable inverted>
						<Table.Body>
							<Table.Row>

								<Table.Cell colSpan="4">
									{this.renderButton(item, "waived")}
								</Table.Cell>
							</Table.Row>

							<Table.Row>
								<Table.Cell colSpan={item.closed ? "1" : "2"}>Created date</Table.Cell>
								<Table.Cell colSpan={item.closed ? "1" : "2"}>{item.created}</Table.Cell>
								{item.closed ?
									[<Table.Cell>Closed date</Table.Cell>,
										<Table.Cell>{item.closed}</Table.Cell>]
									: null}
							</Table.Row>

							{item.examiner != null &&
								<Table.Row>
									<Table.Cell colSpan="2">Examiner</Table.Cell>
									<Table.Cell  colSpan="2">{item.examiner}</Table.Cell>
								</Table.Row>
							}

							<Table.Row>
								<Table.Cell colSpan="2">TankNumber</Table.Cell>
								<Table.Cell colSpan="2">{item.tankNumber}</Table.Cell>
							</Table.Row>

							{this.props.stage !== "completed" && this.props.stage !=="unassigned" && <React.Fragment>
								<Table.Row>
									<Table.HeaderCell style={{padding: "1em"}}>Stage</Table.HeaderCell>
									<Table.HeaderCell style={{padding: "1em"}} colspan="3">Name</Table.HeaderCell>
								</Table.Row>

								<Table.Row>
									<Table.Cell colSpan="1">1 </Table.Cell>
									<Table.Cell colSpan="1">Dim </Table.Cell>
									<Table.Cell colSpan="2">
										{this.renderButton(item, "dim")}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell colSpan="1">1</Table.Cell>
									<Table.Cell colSpan="1">Visual</Table.Cell>
									<Table.Cell colSpan="2">
										{this.renderButton(item, "visual")}
									</Table.Cell>
								</Table.Row>
								<Table.Row>
									<Table.Cell colSpan="1">1</Table.Cell>
									<Table.Cell colSpan="1">NDT</Table.Cell>
									<Table.Cell colSpan="2">
										{this.renderButton(item, "ndt")}
									</Table.Cell>
								</Table.Row>

								<Table.Row>
									<Table.Cell colSpan="1">2</Table.Cell>
									<Table.Cell colSpan="1">QC doc's</Table.Cell>
									<Table.Cell colSpan="2">
										{this.renderButton(item, "qcDoc")}
									</Table.Cell>
								</Table.Row>
							</React.Fragment>
							}
						</Table.Body>
					</Table>
				})}

				<Modal size={"mini"} open={open} onClose={this.close}>
					<Modal.Content>
						<p>{this.state.message}</p>
					</Modal.Content>
					<Modal.Actions>
						<Button negative onClick={
							this.close}>No</Button>
						<Button positive icon='checkmark' labelPosition='right' content='Yes'
						        onClick={() => {
							        this.updateStatus(this.state.currentItem, this.state.name, this.state.method)
						        }}/>
						<Message error hidden={this.state.errorMessage === ""}><Button negative
						                                                               onClick={this.close}>Cancel</Button>{this.state.errorMessage}
						</Message>
					</Modal.Actions>
				</Modal>
			</div>
		);
	}
}

export default InspectionArray;
